import { Alert, Button, Card, Form, Image } from 'react-bootstrap'
import { AuthorizationError } from 'remix-auth'
import { safeRedirect, serverError } from 'remix-utils'
import { z } from 'zod'
import { zx } from 'zodix'
import { getToastIdSession } from '#app/sessions/toast-id.server.ts'
import logo from '~/assets/img/kiz-logo.svg'
import { GeneralErrorBoundary } from '~/components/error-boundary.tsx'
import UIFooter from '~/components/ui/footer.tsx'
import {
	type MetaFunction,
	Form as RemixForm,
	type DataFunctionArgs,
	json,
	Link,
	useActionData,
} from '~/remix.ts'
import {
	authenticator,
	createAuthSession,
	requireAnonymous,
} from '~/sessions/auth.server.ts'
// import { EMIT } from '~/utils/event-emitter.ts'

export const meta: MetaFunction = () => {
	return [{ title: 'Klapton Insurance Portal' }]
}

export async function loader({ request }: DataFunctionArgs) {
	await requireAnonymous(request)

	return null
}

export async function action({ request }: DataFunctionArgs) {
	const { toastId } = await getToastIdSession(request)

	try {
		const { redirect_to } = await zx.parseQuery(request, {
			redirect_to: z
				.string()
				.startsWith('/')
				.optional()
				.default('/dashboard')
				.transform(s => safeRedirect(s, '/dashboard')),
		})

		const user = await authenticator.authenticate('email-pass', request, {
			throwOnError: true,
		})

		return createAuthSession({
			request,
			user,
			headers: new Headers(),
			redirectTo: redirect_to,
		})
	} catch (error) {
		if (error instanceof AuthorizationError) {
			const msg = error.message
			// EMIT.TOAST(msg, toastId, {
			// 	type: 'error',
			// 	autoClose: 5_000,
			// })
			return json({
				title: 'Login failed',
				details: msg,
				variant: 'danger',
			})
		}

		if (error instanceof Response) throw error

		const msg =
			'We could not log you in due to an error on our end. Please try again later. Contact us if the issue persists.'

		throw serverError({ message: msg })
	}
}

export default function Index() {
	const lastSubmission = useActionData<typeof action>()

	return (
		<main>
			<div className="px-4 py-5 px-md-5 text-center text-lg-start min-vh-95">
				<div className="container">
					<div className="row gx-lg-5 align-items-center">
						<div className="mb-5 mb-lg-0">
							<Card className="border-0">
								<Card.Body className="p-5 align-self-center align-items-center w-50">
									<Image
										src={logo}
										alt="KIZ Logo"
										className="text-center"
										style={{
											height: '12rem',
											display: 'block',
											margin: '0 auto',
										}}
									/>
									<p className="mb-4 text-center">
										Enter your details below to log in
									</p>
									{lastSubmission ? (
										<Alert
											className="my-2 p-2"
											variant={lastSubmission.variant}
										>
											<Alert.Heading className="h6">
												{lastSubmission.title}
											</Alert.Heading>
											<p>{lastSubmission.details}</p>
										</Alert>
									) : null}
									<RemixForm method="POST">
										<Form.Group className="mb-3" controlId="email">
											<Form.Label>Email</Form.Label>
											<Form.Control
												type="email"
												name="email"
												required
												autoComplete="email"
											/>
										</Form.Group>
										<Form.Group className="mb-3" controlId="password">
											<Form.Label>Password</Form.Label>
											<Form.Control
												type="password"
												name="password"
												autoComplete="current-password"
												required
											/>
										</Form.Group>
										<Button
											className="btn-warning btn-custom-theme btn-block mt-4 w-100"
											// onClick={() => navigate('/dashboard')}
											type="submit"
											color="#df9100"
										>
											Log in
										</Button>
									</RemixForm>
									<div className=" mt-4 text-center">
										<Link to="/forgot-password" className="text-theme">
											Forgot my password
										</Link>
									</div>
								</Card.Body>
							</Card>
						</div>
					</div>
				</div>
			</div>
			<UIFooter />
			{/* </section> */}
			{/* <Row>
					</Row> */}
			{/* </Container>*/}
		</main>
		// </>
	)
}

export function ErrorBoundary() {
	return <GeneralErrorBoundary />
}
